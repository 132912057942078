<template>
  <div class="folder-content-view" :class="{ 'full-width': !collaborateVisible && !isTransitioning }">
    <div class="folder-article-view-section">
      <div class="folder-article-view-section__header">
        <div class="folder-article-view-section__header--title">
          <button @click="visibleFolderSideBar" class="side-bar-menu">
            <img src="@/assets/icons/menu.svg" />
          </button>
          <div class="folder-icon">
            <img :src="parentFolder?.folder?.icon ??  require('@/assets/icons/glocal-folder-icon.svg')" alt="Icon" />
          </div>
          <h1 v-if="parentFolder.folder || childFolder.folder  || nestedChildFolder.folder" class="title">
            <a-breadcrumb>
              <a-breadcrumb-item v-if="parentFolder.folder">{{ parentFolder.folder.name }}</a-breadcrumb-item>
              <a-breadcrumb-item v-if="childFolder.folder">{{  childFolder.folder.name }}</a-breadcrumb-item>
              <a-breadcrumb-item v-if="nestedChildFolder.folder">{{  nestedChildFolder.folder.name }}</a-breadcrumb-item>
            </a-breadcrumb>
            <!-- <span v-if="nestedChildFolder.folder">{{  nestedChildFolder.folder.name }}</span>
            <span v-else-if="childFolder.folder">{{  childFolder.folder.name }}</span>
            <span v-else>{{ parentFolder.folder.name}}</span> -->
          </h1>
        </div>
        <div class="folder-article-view-section__header--tabs-search">
          <div class="tabs">
            <button
              v-for="(tab, index) in tabs"
              :key="index"
              :class="{
                tabs__tab: true,
                'tabs__active-tab': activeTab === tab.id,
              }"
              @click="setActiveTab(tab.id)"
            >
              <span>{{ tab.label }}</span>
            </button>
          </div>
          <div class="search-container">
            <!-- <div v-if="folderUsers.length > 0 && folderType === 'institute'" class="search-container__users">
              <div class="search-container__users--count">
                <button  @click="visibleUserPermissionDrawer()" class="btn">See all</button>
                <p>{{ folderUsers.length }}</p>
              </div>
              <div class="search-container__users--user-images">
                <img
                  v-for="image in folderUsers.slice(0, 3)"
                  :key="image"
                  :src="image.profilePicture"
                  alt="image"
                />
              </div>
            </div> -->
            <div class="search-container__buttons">
              <!-- <a-tooltip class="link-tooltip" v-if="folderUsers.length > 0 && folderType === 'institute'">
                <template #title>Add Users</template>
                <button class="search-container__buttons--btn" @click="visibleUserPermissionDrawer()">
                  <img src="@/assets/icons/network_icon_black.png" />
                </button>
              </a-tooltip> -->
              <QRCode @click.stop :folder="QRDetails" />
              <!-- <a-tooltip class="link-tooltip">
                <template #title>Downloads</template>
                <button class="search-container__buttons--btn">
                  <img src="@/assets/icons/download-icon.png" />
                </button>
              </a-tooltip> -->
              <a-tooltip class="link-tooltip" v-if="folderType === 'institute'">
                <template #title>Activity Log</template>
                <button class="search-container__buttons--btn" @click="showActivityDrawer()">
                  <img src="@/assets/icons/activity-log-icon.png" />
                </button>
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="folder-article-view-section__body">
        <ArticleCollapseView :articleType="activeTab" v-if="activeTab === 'articles' || activeTab === 'suggested'" :collaborateToggle="collaborateVisible" :isTransitioning="isTransitioning" />
        <MediaSection v-if="activeTab === 'videos'  || activeTab === 'podcasts' || activeTab === 'books'" :isVideoContent="activeTab === 'videos' ? true : false" :isBookContent="activeTab === 'books' ? true : false" :collaborateToggle="collaborateVisible" :isTransitioning="isTransitioning" />
        <!-- <PodcastSection v-if="activeTab === 'podcasts'" :collaborateToggle="collaborateVisible" :isTransitioning="isTransitioning" /> -->
      </div>
    </div>
    <Transition name="slide-fade" @after-leave="afterLeave">
      <div v-if="collaborateVisible" class="collaborate-wrapper">
        <Collaborate discussionType="folder"/>
      </div>
    </Transition>
    <button class="collaborate-btn" @click="toggleCollaborate" :class="{ 'collaborate-btn-active': !collaborateVisible && !isTransitioning }">
      <img src="@/assets/icons/arrow-left-icon.svg" alt="Icon" width="auto" height="auto" :class="{ 'arrow-right': !collaborateVisible && !isTransitioning }" />
    </button>
    <Transition name="slide-fade">
      <FolderMenuSidebar v-if="openFolderBar" />
    </Transition>
  </div>
  <!-- User permissions drawer -->
  <user-permissions-drawer
    :visibleDrawer="visibleUserPermissionsDrawer"
    :drawerPlacement="drawerPlacement"
    @close-drawer="closeUserPermissionsDrawer"
  >
  </user-permissions-drawer>
  <!-- Activity permissions drawer -->
  <activity-log-drawer 
   :drawerVisibility="activityDrawer"
    @close-drawer="closeActivityDrawer()"
  >
  </activity-log-drawer>
</template>

<script>
import MediaSection from "./MediaSection.vue";
import QRCode from "../BaseComponents/QRCodeScanner/QRCode.vue";
import FolderMenuSidebar from "./FolderMenuSidebar.vue";
import ArticleCollapseView from "./ArticleCollapseView.vue";
// import PodcastSection from "./PodcastSection.vue";
import UserPermissionsDrawer from "./UserPermissionsDrawer.vue";
import ActivityLogDrawer from "./ActivityLogDrawer.vue";
import foldersMixin from "../../mixins/folders.mixnin";
import Collaborate from "../PdbComponents/Collaborate.vue";
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      openFolderBar: false,
      activeTab: "articles",
      visibleUserPermissionsDrawer: false,
      activityDrawer: false,
      tabs: [
        { id: "articles", label: "Articles" },
        { id: "videos", label: "Videos" },
        { id: "podcasts", label: "Podcasts" },
        { id: "books", label: "Books" },
        { id: "suggested", label: "Suggested Articles by Glocal" },
      ],
      collaborateVisible: false,
      isTransitioning: false,
    };
  },
  mixins:[foldersMixin],
  components: {
    FolderMenuSidebar,
    QRCode,
    MediaSection,
    ArticleCollapseView,
    // PodcastSection,
    UserPermissionsDrawer,
    ActivityLogDrawer,
    Collaborate
  },
  created(){
    // fetch collapsed state from localStorage
    const collapsedState = this.authUser?.profile?.settings?.is_folder_topic_collaborate_collapsed ?? true
    this.collaborateVisible = collapsedState
    if (window.innerWidth <= 786)
      this.collaborateVisible = false
  },
  mounted() {
    this.emitter.on("close-folder-side-bar", () => {
      this.openFolderBar = false;
    });
  },
  methods: {
    ...mapActions({
      updateUserSettings: 'profile/updateUserSettings'
    }),
    visibleFolderSideBar() {
      this.openFolderBar = true;
    },
    setActiveTab(tabId) {
      this.activeTab = tabId;
    },
    visibleUserPermissionDrawer() {
      if (window.innerWidth < 576) {
        this.drawerPlacement = 'bottom'
      } else if (window.innerWidth > 576) {
        this.drawerPlacement = 'right'
      }
      this.visibleUserPermissionsDrawer = true
    },
    closeUserPermissionsDrawer() {
      this.visibleUserPermissionsDrawer = false
    },
    showActivityDrawer() {
      this.activityDrawer = true
    },
    closeActivityDrawer() {
      this.activityDrawer = false
    },
    toggleCollaborate() {
      this.isTransitioning = true;
      this.collaborateVisible = !this.collaborateVisible;
      const userSettings = this.authUser?.profile?.settings
      userSettings.is_folder_topic_collaborate_collapsed = this.collaborateVisible
      this.updateUserSettings({settings: userSettings, user_id:this.authUser?.id})
    },
    afterLeave() {
      // After the leave transition, reset the flag to allow full-width
      this.isTransitioning = false;
    }
  },
  computed: {
    ...mapGetters({
       authUser: 'profile/userInformation',
    }),
    activeComponent() {
      return this.components[this.activeTab];
    },
    parentFolder() {
      return this.$store.getters["folders/getParentFolderDetails"];
    },
    childFolder() {
      return this.$store.getters["folders/getChildFolderDetails"];
    },
    nestedChildFolder() {
      return this.$store.getters["folders/getNestedChildFolderDetails"];
    },
    folderUsers() {
      return this.$store.getters["folders/getUsersWithFolderAccess"];
    },
    QRDetails() {
      let folder = this.childFolder.folder
        ? this.childFolder.folder
        : this.parentFolder.folder;
      return folder;
    },
  },
};
</script>

<style lang="scss">
.folder-content-view {
  display: grid;
  grid-template-columns: 60% 40%;
  position: relative;
  &.full-width {
    grid-template-columns: 100% !important;
  }
  // Responsive adjustments
  @include respond(laptop-medium) {
    grid-template-columns: 58% 42%;
  }
  @include respond(tab-land) {
    grid-template-columns: 100%;
  }
  .folder-article-view-section {
    height: calc(100vh - 8rem);
    overflow-y: auto;
    overflow-x: hidden;
    @include respond(tab-port) {
      height: calc(100vh - 12rem);
    }
    &__header {
      padding: 2rem 3rem 0;
      margin-bottom: 2rem;
      position: sticky;
      top: 0;
      background-color: $color-white;
      z-index: 1;
      border-bottom: 1px solid $color-dark-grey-5;
      @include respond(tab-port) {
        padding: 1.9rem 2rem 0;
      }
      @include respond(phone-x-small) {
        padding: 1.9rem 2.4rem 0;
      }
      &--title {
        display: flex;
        align-items: center;
        width: fit-content;
        min-height: 2.4rem;
        .side-bar-menu {
          display: none;
          margin-right: 2.5rem;
          line-height: normal;
          padding: 0;
          height: fit-content;
          width: fit-content;
          border: none;
          background-color: transparent;
          color: $color-black;
          outline: none;
          cursor: pointer;
          margin-top: 0.5rem;
          @include respond(tab-port) {
            display: block;
          }
          img {
            width: 3.2rem;
          }
        }
        .folder-icon {
          margin-right: 2rem;
          width: 2.2rem;
          height: 2.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 2rem;
          font-family: $font-primary-medium;
          line-height: 2.4rem;
          color: $color-black;
          margin-bottom: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          @include respond(laptop-small) {
            max-width: 74%;
          }
          .ant-breadcrumb {
            font-size: 2rem;
            line-height: 2.4rem;
          }
        }
      }
      &--tabs-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3rem;
        @include respond(tab-port) {
          padding: 2rem 0 0;
        }
        .tabs {
          width: auto;
          display: flex;
          align-items: center;
          gap: 1.6rem;
          overflow-x: auto;
          overflow-y: hidden;
          &__tab {
            padding: 1.2rem 1.6rem 1rem;
            display: flex;
            align-items: center;
            width: max-content;
            justify-content: center;
            border: none;
            outline: none;
            background-color: $color-light-grey-3;
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            line-height: normal;
            color: $color-dark-grey-6;
            border-top-right-radius: 1rem !important;
            border-top-left-radius: 1rem !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            cursor: pointer;
            margin-bottom: 0;
            height: 4rem;
            span {
              display: block;
              width: max-content;
              line-height: normal;
              margin-bottom: -0.1rem;
            }
          }
          &__active-tab {
            background-color: $color-primary;
            color: $color-white;
          }
          &::-webkit-scrollbar {
            height: 0.4rem;
          }

          &::-webkit-scrollbar-track {
            background-color: $color-white;
            border-radius: 0.8rem;
            height: 0.4rem;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 0.8rem;
          }

          @include respond(phone-x-small) {
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
        .search-container {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 1.6rem;
          margin-bottom: 0;
          padding-left: 1rem;
          margin-top: -0.7rem;
          &__buttons {
            display: flex;
            align-items: center;
            gap: 1.6rem;
            &--btn {
              width: 4.4rem;
              height: 4.4rem;
              border: none;
              outline: none;
              border-radius: 100% !important;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $color-white;
              cursor: pointer;
              background-color: #e1e8ff;
              img {
                width: 1.7rem;
                height: 1.7rem;
              }
            }
            .qr-code-scanner-container {
              &__btn {
                width: 4.4rem;
                height: 4.4rem;
                background-color: #e1e8ff;
              }
              &__scanner-dropdown {
                top: 7rem;
                right: -0.5rem;
              }
            }
          }
          &__search-bar {
            border: 1px solid $color-dark-grey-5;
            display: flex;
            align-items: center;
            padding: 0 1.8rem;
            border-radius: 100px;
            width: calc(100% - 136px);
            background-color: #efefef;
            max-width: 30rem;
            .icon {
              font-size: 1.7rem;
              color: $color-primary;
            }
            .icon-add_plus {
              transform: rotate(45deg);
              display: block;
              font-size: 1.4rem;
              color: $color-black;
              cursor: pointer;
            }
            .search-bar {
              border: none;
              padding: 0;
              padding-left: 1.3rem;
              background-color: transparent;
              height: 5.1rem;
              font-size: 1.4rem;
              outline: none;
              border: none;
              padding-right: 1rem;
              width: 100%;
              &::placeholder {
                color: $color-black;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
    &__body {
      padding: 0 3rem 2rem;
      @include respond(tab-port) {
        padding: 0 2rem 1.5rem;
      }
      @include respond(phone-x-small) {
        padding: 0 2.4rem 1.5rem;
      }
    }
    &::-webkit-scrollbar {
      width: 0.3rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 0.8rem;
      height: 5rem;
    }
  }
  .collaborate-wrapper {
    position: relative;
    width: calc(100% - 2.9rem);
    .collaborate-container {
      position: absolute;
      right: 0;
      bottom: 2rem;
      width: calc(100% - 2.9rem);
      margin: 0;
      z-index: 1;
      background-color: $color-white;
      @media (min-width: 1281px) {
        height: calc(100vh - 14.6rem);
        &__body {
          height: calc(100vh - 30.5rem);
        }
      }
      @include respond(tab-land) {
        bottom: 2rem;
        height: calc(100vh - 16.4rem);
      }
      @include respond(phone-x-small) {
        bottom: 6rem !important;
      }
    }
    @include respond(tab-port) {
      width: calc(100% - 1.3rem);
      .collaborate-container  {
        width: calc(100% - 1.4rem);
        bottom: 1.5rem;
      }
    }
  }
  .collaborate-btn {
    background-color: #ebebeb;
    cursor: pointer;
    z-index: 100;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 100% !important;
    position: absolute;
    top: 27rem;
    right: calc(40% - 5.2rem);
    transition: all .3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    border: none;
    img {
      width: 2.5rem;
      height: auto;
      transform: rotate(180deg);
    }
    .arrow-right {
      transform: rotate(0deg);
    }
    @include respond(laptop-medium) {
      right: calc(42% - 3rem);
    }
    @include respond(tab-land) {
      right: 1rem;
    }
    @include respond(tab-port) {
      top: 35rem;
      right: -1rem !important;
    }
    @include respond(phone-x-small) {
      right: -0.7rem !important;
    }
  }
  .collaborate-btn-active {
    background-color: #e1e8ff;
    right: 3.5rem;
    @include respond(tab-port) {
      right: 2rem !important;
    }
    @include respond(phone-x-small) {
      right: 2.2rem !important;
    }
  }
}


.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  transform: translateX(-100%);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
